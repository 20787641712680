import React from 'react';
import styled from 'styled-components';
import Logo from './Logo';
import Brandmark from './Brandmark';
import { Button, TextField } from '@material-ui/core';
import { gradient } from '../util/theme';
import { useForm } from 'react-hook-form';
import EmailSubscription from './EmailSubscription';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1 {
        font-weight: 800;
        text-align: center;
        font-size: 64px;
        margin: 4rem 0 3rem;
        line-height: 1;
        letter-spacing: -1.5px;
        max-width: 90%;

        @media (min-width: 900px) {
            margin-top: 6rem;
        }
    }
`;

const LogoContainer = styled.div`
	width: 200px;
    padding: 0 2rem;
    max-width: 100%;
    margin-bottom: 3rem;
`;

const Messaging = styled.div`
    width: 500px;
    padding: 0 2rem;
    max-width: 100%;
    font-style: italic;
    
    p {
        font-size: 18px;
        text-align: center;
    }
`;

const Signature = styled.p`
	text-align: center;
    font-size: 18px;
    margin-bottom: 4rem;

    @media (min-width: 900px) {
        margin-bottom: 8rem;
    }
`;

const SubscriptionContainer = styled.div`
    width: 420px;
    padding: 0 1rem;
    max-width: 100%;
    margin-bottom: 1rem;

    p {
        font-size: 14px;
        text-align: center;
    }
`;

const Maintenance: React.FC = () => {


    return (
        <Container>
            <h1>Be back soon!</h1>
            <LogoContainer>
                <Brandmark />
            </LogoContainer>
            <Messaging>
                <p>
                    I'm busy installing a beautiful new sheet music store.
                    Don't worry, I'll come back to you when it's ready!
                </p>
            </Messaging>
            <Signature>-Pauliano</Signature>

            <SubscriptionContainer>
                <p>
                    If you'd like a notification when the store becomes
                    available, leave your email address below.
                </p>
                <EmailSubscription />
            </SubscriptionContainer>
        </Container>
    );
};

export default Maintenance;
