import { Link } from 'gatsby';
import styled from 'styled-components';
import { Redirect } from '@reach/router';
import React, { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import { modularScale } from '../util/modularScale';
import { Product } from '../types/types';
import { gradient, navy, slate } from '../util/theme';
import ProductLink from './ProductLink';
import { Button } from '@material-ui/core';

const Container = styled.div`
    position: relative;
	padding: 0 1rem;
	width: 100%;
	margin: 0 auto;
	overflow: hidden;
	min-height: 80vh;
	
	&:after {
	    content: "";
        position: absolute;
        top: 325px;
        left: 0;
        width: 100%;
        height: 2000px;
        background: ${navy};
        transform: skewY(-5deg);
        transform-origin: top;
        z-index: -1;
	}
`;

const Title = styled.h1`
    font-size: ${modularScale(2)};
	font-weight: 400;
	text-align: center;
	
	@media (min-width: 900px) {
	    margin-bottom: 2rem;
	}
`;

const StyledProductLink = styled(ProductLink)`
    @media (min-width: 900px) {
        margin: 1rem;
	}
	
	@media (min-width: 2000px) {
	    margin: 1rem 2rem;
	}
`;

const ProductList = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	
	@media (min-width: 900px) {
	    flex-direction: row;
	    flex-wrap: wrap;
	    justify-content: center;
	}
`;

const Actions = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 3rem 0 6rem;
`;

interface Props {
    products: Product[];
}


const StyledButton = styled(Button)`
	color: #fff;
    background: ${gradient};
    margin-bottom: 1rem;
    
    &:focus, &:hover, &:active {
    	color: #fff;
        background: ${gradient};
    }
`;

const Home: React.FC<Props> = ({ products }) => {

    const getLatestProducts = (count: number): Product[] => {
        const productList = products.slice();

        const latest = productList.sort((a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );

        return latest.slice(0, count);;
    }

    const getProducts = (): Product[] => {
        const productList = products.slice();
        const items: Product[] = [];

        const first = productList.find(i => i.title.includes('Deja Vu'));
        const second = productList.find(i => i.title.includes('Moon River'));
        const third = productList.find(i => i.title.includes('You Say'));

        const addProduct = (item) => {
            if (item) items.push(item);
        }

        addProduct(first);
        addProduct(second);
        addProduct(third);

        return items;
    }

    const renderProducts = () => {
        let productList = getProducts();

        if (productList.length < 3) {
            productList = getLatestProducts(3);
        }

        return productList.map((product, i) => <StyledProductLink product={product} key={i} />);
    }

    return (
        <Container>
            <Title>
                New Sheet Music
            </Title>
            <ProductList>
                {renderProducts()}
            </ProductList>
            <Actions>
                <StyledButton size="large" component={Link} to='/sheets/' variant="contained">Browse Collection</StyledButton>
            </Actions>
        </Container>
    );
};

export default Home;
