import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, TextField } from '@material-ui/core';
import styled from 'styled-components';
import { gradient, primary } from '../util/theme';
import axios, { handleError } from '../util/axios';
import Icon from '@mdi/react';
import { mdiCheckCircleOutline } from '@mdi/js';
import Spinner from './Spinner';
import { Alert } from 'antd';

const StyledInput = styled(TextField)`
	margin-bottom: 0.5rem;
`;

const StyledButton = styled(Button)`
    color: #fff !important;
    background-color: ${primary};
	height: 42px;
    margin-bottom: 0.5rem;
	
	&.Mui-disabled {
	    background-color: #acacac;
	    
            &:hover, &:focus, &:active {
            background-color: #acacac;
        }
	}
	
	&:hover, &:focus, &:active {
        background-color: ${primary};
	}
	
	svg {
	    width: 24px;
	}
`;

const StyledForm = styled.form`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const ButtonText = styled.div`
	display: flex;
	align-items: center;
	
	svg {
	    width: 18px;
	    margin-left: 0.5rem;
	}
`;

const EmailSubscription: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [requestError, setRequestError] = useState('');
    const { register, handleSubmit, errors } = useForm();

    const onSubmit = async data => {
        setIsLoading(true);
        setIsDisabled(true);

        try {
            await axios.post('/email', data);
            setIsSuccess(true);
        } catch (err) {
            setRequestError('An error occurred while subscribing. Please try again later.');
        }

        setIsLoading(false);
    }

    const getButtonText = () => {
        if (isSuccess) {
            return <ButtonText>Subscribed <Icon path={mdiCheckCircleOutline} /></ButtonText>
        }

        if (isLoading) {
            return <Spinner color={'#fff'} />
        }

        return 'Notify Me';
    }

    return (
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <StyledInput variant="outlined" type="email" label="email" name="email" title="email" inputRef={register({ required: true })} size="small" fullWidth/>
            <StyledButton variant="contained" fullWidth type="submit" disabled={isDisabled}>
                {getButtonText()}
            </StyledButton>
            { requestError &&
                <Alert message={requestError} />
            }
        </StyledForm>
    );
};

export default EmailSubscription;
