import React from 'react';

const Brandmark: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.55 68.55">
            <defs>
                <filter id="shadow" x="-90%" y="-90%" width="280%" height="280%">
                    <feDropShadow dx="0" dy="0" stdDeviation="5" floodColor="rgba(0,0,0,0.75" />
                </filter>
                <clipPath id="clip-path">
                    <path className="cls-1" d="M47.64,2.7A34.28,34.28,0,1,0,68.55,34.27,34.18,34.18,0,0,0,47.64,2.7Z"/>
                </clipPath>
                <linearGradient id="linear-gradient" x1="20.86" y1="36.54" x2="20.86" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#f16e76"/>
                    <stop offset="1" stopColor="#e6548a"/>
                </linearGradient>
                <linearGradient id="linear-gradient-2" x1="26.72" y1="53.83" x2="26.72" y2="8.35" xlinkHref="#linear-gradient"/>
                <linearGradient id="linear-gradient-3" x1="49.89" y1="45.19" x2="49.89" y2="0" xlinkHref="#linear-gradient"/>
            </defs>
            <g style={{ clipPath: 'url(#clip-path)' }}>
                <path style={{ fill: '#222b38' }} d="M47.65,2.7a34.29,34.29,0,1,0,20.9,31.57A34.22,34.22,0,0,0,47.65,2.7Z"/>
                <path style={{ fill: 'url(#linear-gradient)', filter: 'url(#shadow)' }} d="M14.55,0V31.43a5.1,5.1,0,0,0,5.1,5.11h2.41a5.11,5.11,0,0,0,5.11-5.11V0Z"/>
                <rect style={{ fill: 'url(#linear-gradient-2)', filter: 'url(#shadow)' }} x="20.41" y="8.35" width="12.62" height="45.49" rx="5.1"/>
                <path style={{ fill: 'url(#linear-gradient-3)', filter: 'url(#shadow)' }} d="M43.58,0V40.08a5.1,5.1,0,0,0,5.1,5.11H51.1a5.11,5.11,0,0,0,5.1-5.11V0Z"/>
                <path style={{ fill: 'none' }} d="M47.64,2.7A34.28,34.28,0,1,0,68.55,34.27,34.18,34.18,0,0,0,47.64,2.7Z"/>
            </g>
        </svg>
    );
};

export default Brandmark;
