import React from 'react';
import SEO from '../seo/SEO';
import styled from 'styled-components';

interface Props {
    customSEO?: boolean;
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

const NoLayout: React.FC<Props> = ({ customSEO, children }) => {
    return (
        <Container>
            {!customSEO && <SEO />}
            {children}
        </Container>
    );
};

export default NoLayout;
