import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Product } from '../types/types';
import { modularScale } from '../util/modularScale';
import { gradient, secondary } from '../util/theme';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

interface Props {
    product: Product;
}

const ProductContainer = styled(Link)`
    position: relative;
    display: flex;
    flex: 0 0 320px;
    flex-direction: column;
	margin-bottom: 1rem;
    width: 320px;
    max-width: 100%;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.15);
    border-radius: 10px;
    z-index: 1;
`;

const ImageWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;
	background-color: #fff;
	max-width: 100%;
	border-radius: 10px;
	overflow: hidden;
`;

const Info = styled.div`
    position: absolute;
    width: 100%;
    right: -1rem;
    bottom: 3rem;
	padding: 1rem;
	background: ${gradient};
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.15);
	color: #fff;
	border-radius: 8px 0 0 8px;
	
	&:before {
	    content: '';
	    position: absolute;
	    height: 1.5rem;
	    width: 1rem;
        background-color: ${secondary};
	    transform: rotate(45deg);
	    right: 0;
	    bottom: -1.5rem;
	    transform-origin: top right;
	    z-index: -1;
	}
`;

const Title = styled.div`
    font-size: ${modularScale(0)};
    overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-weight: bold;
`;

const Subtitle = styled.div`
	font-size: ${modularScale(-1)};
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const ProductLink: React.FC<Props> = ({ product, ...props }) => {
    const image = getImage(product.localImage);

    return (
        <ProductContainer to={'/sheets/' + product.slug} {...props}>
            <ImageWrapper>
                {image && <GatsbyImage alt={product.title + ' Sheet Music'} image={image} />}
            </ImageWrapper>
            <Info>
                <Subtitle>{product.subtitle}</Subtitle>
                <Title>{product.title}</Title>
            </Info>
        </ProductContainer>
    );
};

export default ProductLink;
