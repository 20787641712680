import React from "react";
import Layout from "../components/layouts/Layout";
import Home from "../components/Home";
import { graphql } from 'gatsby';
import { Product } from '../types/types';
import NoLayout from '../components/layouts/NoLayout';
import Maintenance from '../components/Maintenance';

interface Props {
    data: any;
}

const IndexPage: React.FC<Props> = ({ data }) => {
    if (process.env.MAINTENANCE_MODE) {
        return (
            <NoLayout>
                <Maintenance />
            </NoLayout>
        )
    }

    return (
        <Layout>
            <Home products={data.allProduct.nodes}/>
        </Layout>
    );
}

export const query = graphql`
    query {
        allProduct {
            nodes {
                subtitle
                slug
                title
                created_at
                localImage {
                    childImageSharp {
                        gatsbyImageData(
                            width: 320
                            layout: CONSTRAINED
                            placeholder: DOMINANT_COLOR
                            formats: [AUTO, WEBP]
                        )
                    }
                }
            }
        }
    }
`;

export default IndexPage;
